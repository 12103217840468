import React, { useRef, useEffect, useState } from 'react';
import './App.css';
import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import ContactBox from "./ContactBox";
import { Howl } from 'howler';

import monologueSound from './monologue.ogg';

import helvetikerFont from '../node_modules/three/examples/fonts/helvetiker_regular.typeface.json';

function LemhLogo() {
  const containerRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const meshesRef = useRef([]);
  const backgroundMeshRef = useRef(null);

  const initScene = () => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000);
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 10;
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    containerRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    addLights(scene);
  };

  const addLights = (scene) => {
    const ambientLight = new THREE.AmbientLight(0x404040, 1.5);
    scene.add(ambientLight);

    const pointLight1 = new THREE.PointLight(0x8b5cf6, 1, 100);
    pointLight1.position.set(10, 10, 10);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0x10b981, 1, 100);
    pointLight2.position.set(-10, -10, -10);
    scene.add(pointLight2);
  };

  const createBackground = (scene) => {
    const geometry = new THREE.PlaneGeometry(20, 20);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 }
      },
      vertexShader: `
        varying vec2 vUv;
        uniform float time;
        void main() {
          vUv = uv;
          vec3 pos = position;
          pos.z += sin(pos.x * 2.0 + time) * 0.1;
          pos.z += cos(pos.y * 2.0 + time) * 0.1;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
        }
      `,
      fragmentShader: `
        uniform float time;
        varying vec2 vUv;
        void main() {
          vec3 color1 = vec3(0.05, 0.01, 0.1);
          vec3 color2 = vec3(0.1, 0.05, 0.2);
          float noise = fract(sin(dot(vUv, vec2(12.9898, 78.233))) * 43758.5453);
          noise += sin(time * 0.5) * 0.1;
          vec3 mixedColor = mix(color1, color2, noise);
          gl_FragColor = vec4(mixedColor, 0.8);
        }
      `,
      transparent: true
    });

    const backgroundMesh = new THREE.Mesh(geometry, material);
    backgroundMesh.position.z = -10;
    scene.add(backgroundMesh);
    backgroundMeshRef.current = backgroundMesh;
  };

  const createLetters = () => {
    const fontLoader = new FontLoader();
    const font = fontLoader.parse(helvetikerFont);

    const letters = ['L', 'E', 'M', 'H'];
    const colors = [0xf87171, 0x8b5cf6, 0xfb923c, 0x10b981];
    const glowColors = [0xff4444, 0x6a4cff, 0xff7f32, 0x2ecc71];

    letters.forEach((letter, index) => {
      const geometry = new TextGeometry(letter, {
        font: font,
        size: 2,
        height: 0.5,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.1,
        bevelSize: 0.1,
        bevelOffset: 0,
        bevelSegments: 5
      });

      geometry.computeBoundingBox();
      geometry.center();

      const material = new THREE.MeshStandardMaterial({
        color: colors[index],
        metalness: 0.7,
        roughness: 0.3,
        emissive: glowColors[index],
        emissiveIntensity: 0.5
      });

      const letterMesh = new THREE.Mesh(geometry, material);
      letterMesh.position.x = (index - 1.5) * 3;
      letterMesh.position.z = 0;

      // Ajouter un effet de glow
      const glowMaterial = new THREE.MeshBasicMaterial({
        color: glowColors[index],
        transparent: true,
        opacity: 0.3
      });
      const glowGeometry = new THREE.BufferGeometry().copy(geometry);
      const glowMesh = new THREE.Mesh(glowGeometry, glowMaterial);
      glowMesh.scale.multiplyScalar(1.1);

      sceneRef.current.add(letterMesh);
      sceneRef.current.add(glowMesh);

      meshesRef.current.push({ letter: letterMesh, glow: glowMesh });
    });
  };

  const animate = () => {
    const timeScale = performance.now() * 0.001;
    requestAnimationFrame(animate);

    // Animation des lettres
    meshesRef.current.forEach(({ letter, glow }, index) => {
      letter.rotation.y = Math.sin(timeScale + index) * 0.5;
      letter.rotation.x = Math.cos(timeScale + index) * 0.3;
      letter.position.z = Math.sin(timeScale + index * 0.5) * 0.1;

      // Synchroniser le glow avec la lettre
      glow.rotation.copy(letter.rotation);
      glow.position.copy(letter.position);
    });

    // Animation du fond
    if (backgroundMeshRef.current) {
      backgroundMeshRef.current.material.uniforms.time.value = timeScale;
    }

    // Effet de respiration
    const breatheScale = Math.sin(timeScale) * 0.05 + 1;
    meshesRef.current.forEach(({ letter, glow }) => {
      letter.scale.set(breatheScale, breatheScale, breatheScale);
      glow.scale.set(
          breatheScale * 1.1,
          breatheScale * 1.1,
          breatheScale * 1.1
      );
    });

    rendererRef.current.render(sceneRef.current, cameraRef.current);
  };

  const handleResize = () => {
    const { innerWidth, innerHeight } = window;
    cameraRef.current.aspect = innerWidth / innerHeight;
    cameraRef.current.updateProjectionMatrix();
    rendererRef.current.setSize(innerWidth, innerHeight);
  };

  useEffect(() => {
    initScene();
    createLetters();

    animate();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      containerRef.current.removeChild(rendererRef.current.domElement);
    };
  }, []);

  return <div ref={containerRef} className="w-full h-full absolute top-0 left-0" />;
}


function Accueil({ onNavClick }) {
  return <div className="relative z-20 text-center">
    <h1 className="text-8xl font-extrabold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
      <HoverName/>
    </h1>
    <p className="text-2xl max-w-3xl mx-auto mb-16 text-gray-300 mt-6 leading-relaxed">
      Bienvenue chez LEMH, pas de monologue ici.
    </p>
    <div className="space-x-6">
      <button className="px-8 py-4 text-xl bg-blue-500 hover:bg-blue-600 rounded-full transition-colors">
       <a
           onClick={(e) => {
             e.preventDefault();
             onNavClick("À propos");
           }}>
         Découvrir
       </a>
      </button>
      <button
          className="px-8 py-4 text-xl border border-white hover:bg-white hover:text-black rounded-full transition-colors">
        <a
            onClick={(e) => {
              e.preventDefault();
              onNavClick("Projets");
            }}>
          Nos projets
        </a>
      </button>
    </div>
  </div>
}

function Projets() {
  return <div>
    <h2 style={{fontSize: '64px', color: 'white', zIndex: "999", position: "relative"}}>Projets</h2>
    <h2 style={{fontSize: '48px', color: 'white', zIndex: "999", position: "relative"}}>À venir, pour la NDI...</h2>
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg transform transition-transform hover:scale-105">
      <h2 className="text-3xl font-bold text-white mb-4">Minecraftdle</h2>
      <img src={require('./img.png')} className="w-1/2 h-auto rounded-md mb-4" alt="Minecraftdle"></img>
      <a href={"https://minecraftdle.net"} className="text-blue-400 hover:text-blue-600 transition-colors">Viens là</a>
    </div>
  </div>;
}

function APropos() {
  return <div>
    <h2 style={{fontSize: '64px', color: 'white', zIndex: "999", position: "relative"}}>À propos</h2>
    <h2 style={{fontSize: '48px', color: 'white', zIndex: "999", position: "relative"}}>À venir...</h2>
  </div>;
}

function Contact() {
  return <>
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <h2 style={{fontSize: '64px', color: 'white', zIndex: "999", position: "relative"}}>Contact</h2>
      <ContactBox mail="contact@lemh.fr" name="Contact du site"/>
      <ContactBox mail="loan.collomb@u-bordeaux.fr" name="Loan" info={{githubUsername:"nesx64"}}/>
      <ContactBox mail="evan.rimonteil@u-bordeaux.fr" name="Evan" info={{githubUsername:"evanrmtl"}}/>
      <ContactBox mail="mateo.guidi@etu.u-bordeaux.fr" name="Matéo" info={{githubUsername:"MateoGuidi"}}/>
      <ContactBox mail="hugo.retail@u-bordeaux.fr" name="Hugo" info={{githubUsername:"hugoretail"}}/>
    </div>
  </>;
}

// Reste du code identique à la version précédente
export default function LemhWebsite() {

  useEffect(() => {
    const konamiKeys = {
      37: 'left',
      38: 'up',
      39: 'right',
      40: 'down',
      65: 'a',
      66: 'b'
    };

    const konamiSequence = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'];
    let pos = 0;

    const enableKonami = () => {
      const audio = new Howl({
        src: [monologueSound],
        autoplay: true,
        volume: 1.0,
        onloaderror: (id, error) => console.error('Failed to load sound:', error),
        onplayerror: (id, error) => console.error('Failed to play sound:', error)
      });
    };

    const handleKeyDown = (event) => {
      let key = konamiKeys[event.keyCode];
      let requiredKey = konamiSequence[pos];
      if (key === requiredKey) {
        pos++;
        if (pos === konamiSequence.length) {
          enableKonami();
          pos = 0; // Optionnel, pour réinitialiser la séquence après avoir joué la musique
        }
      } else {
        pos = 0;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleNavigationClick = (page) => {
    setActivePage(page);
  };

  const [activePage, setActivePage] = useState('Accueil');

  return (
      <div
          className="min-h-screen bg-black text-white overflow-hidden relative flex flex-col items-center justify-center">
        <Navigation onNavClick={handleNavigationClick}/>

        <div className="absolute inset-0 opacity-50">
          <LemhLogo/>
        </div>
        <div className="content-container">
        {activePage === 'Accueil' && <Accueil onNavClick={handleNavigationClick} />}
        {activePage === 'Projets' && <Projets />}
        {activePage === 'À propos' && <APropos />}
        {activePage === 'Contact' && <Contact />}
      </div>
    </div>
  );
}


// Composants Navigation et HoverName restent identiques
function Navigation({ onNavClick }) {
  const navItems = [
    { name: 'Accueil', link: 'Accueil' },
    { name: 'Projets', link: 'Projets' },
    { name: 'À propos', link: 'À propos' },
    { name: 'Contact', link: 'Contact' },
  ];

  return (
      <nav className="fixed top-0 left-0 right-0 z-50 bg-opacity-50 bg-gray-900 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-2xl font-bold text-white">LEMH</div>
          <ul className="flex space-x-6">
            {navItems.map((item) => (
                <li key={item.name}>
                  <a
                      href=""
                      className="text-white hover:text-blue-400 transition-colors"
                      onClick={(e) => {
                          e.preventDefault();
                          onNavClick(item.link);
                      }}
                  >
                    {item.name}
                  </a>
                </li>
            ))}
          </ul>
        </div>
      </nav>
  );
}



function HoverName() {
  const [hoveredLetter, setHoveredLetter] = useState(null);

  const letterDetails = {
    L: { full: "Loan", color: "text-red-400" },
    E: { full: "Evan", color: "text-violet-400" },
    M: { full: "Matéo", color: "text-orange-400" },
    H: { full: "Hugo", color: "text-emerald-500" },
  };

  return (
    <div className="flex space-x-12 items-center justify-center">
      {Object.entries(letterDetails).map(([letter, details]) => (
        <div
          key={letter}
          className="flex items-center space-x-4"
          onMouseEnter={() => setHoveredLetter(letter)}
          onMouseLeave={() => setHoveredLetter(null)}
        >
          {/* Lettre */}
          <span
            className={`text-8xl font-bold transition-transform duration-300 ${
              hoveredLetter === letter ? "scale-125" : "scale-100"
            } ${details.color}`}
          >
            {letter}
          </span>

          {/* Texte étendu avec taille augmentée */}
          <span
            className={`text-4xl font-semibold overflow-hidden transition-all duration-300 ${
              hoveredLetter === letter ? "opacity-100 max-w-xs" : "opacity-0 max-w-0"
            } ${details.color}`}
            style={{ whiteSpace: "nowrap" }}
          >
            {details.full}
          </span>
        </div>
      ))}
    </div>
  );
}
